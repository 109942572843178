<template>
  <div>
    <div
      :id="id"
      :aria-controls="`${id}-region`"
      class=" p-4 flex justify-between items-center w-full cursor-pointer focus:outline-none"
      :class="overrideClasses"
      role="button"
      tabindex="0"
      @click="onToggleContent"
    >
      <slot name="header" />
      <icon
        :icon="icon"
        :color="iconColor"
        :class="[
          'transition-all transition-200 -rotate-90',
          { '-rotate-90': showContent },
          { 'rotate-90': !showContent },
        ]"
      />
    </div>
    <smooth-wrapper>
      <div
        v-if="showContent"
        :id="`${id}-region`"
        :aria-labelledby="id"
        role="region"
      >
        <slot name="content" />
      </div>
    </smooth-wrapper>
  </div>
</template>

<script>
import uuid from 'uuid/v1'
import { Icon } from '~/components/atoms'
import SmoothWrapper from '~/components/utils/smooth-wrapper'

export default {
  components: {
    Icon,
    SmoothWrapper,
  },
  props: {
    showInitialContent: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'link',
    },
    overrideClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showContent: process.server || this.showInitialContent,
      id: uuid(),
    }
  },
  computed: {
    icon() {
      return this.showContent ? 'min' : 'plus'
    },
  },
  mounted() {
    this.showContent = this.showInitialContent
  },
  methods: {
    onToggleContent() {
      this.showContent = !this.showContent
    },
    setShowContent(isShow) {
      this.showContent = isShow
    },
  },
}
</script>
